import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d3f5430"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "msg-list",
  id: "msgList"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "msg-title" }
const _hoisted_5 = { class: "msg-info" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "msg-replynum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_tag = _resolveComponent("n-tag")!
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_tabs = _resolveComponent("n-tabs")!
  const _component_n_spin = _resolveComponent("n-spin")!

  return (_openBlock(), _createBlock(_component_n_spin, { show: _ctx.isLoading }, {
    default: _withCtx(() => [
      _createVNode(_component_n_tabs, {
        type: "line",
        animated: "",
        onUpdateValue: _ctx.handleChangeTab
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataInfo.options, (item) => {
            return (_openBlock(), _createBlock(_component_n_tab_pane, {
              key: item.value,
              name: item.label,
              tab: item.label
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.msgList, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "msg-item",
                      key: item.id
                    }, [
                      _createElementVNode("img", {
                        class: "msg-img",
                        src: item.avatar || _ctx.DEFAULT_AVATAR
                      }, null, 8, _hoisted_2),
                      _createElementVNode("div", {
                        class: "msg-content",
                        onClick: ($event: any) => (_ctx.handleDetail(item))
                      }, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(item.parameter), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", {
                            class: "msg-info-author",
                            onClick: _withModifiers(($event: any) => (_ctx.handleUserDetail(item.author)), ["stop"])
                          }, [
                            _createElementVNode("strong", null, _toDisplayString(item.web3Name || item.author), 1)
                          ], 8, _hoisted_6),
                          _createElementVNode("div", null, _toDisplayString(_ctx.timeFromNow(item.time)), 1),
                          (item.sort.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.sort.split(','), (tag) => {
                                  return (_openBlock(), _createBlock(_component_n_tag, {
                                    key: tag,
                                    type: "info",
                                    size: "small"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(tag), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ], 8, _hoisted_3),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(item.replynum), 1)
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1032, ["name", "tab"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onUpdateValue"])
    ]),
    _: 1
  }, 8, ["show"]))
}