import { AccountKit } from '@wharfkit/account'
import ContractKit from '@wharfkit/contract'
import SessionKit, { APIClient, Chains } from '@wharfkit/session'
import { TransactPluginResourceProvider } from '@wharfkit/transact-plugin-resource-provider'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginScatter } from '@wharfkit/wallet-plugin-scatter'
import { WalletPluginTokenPocket } from '@wharfkit/wallet-plugin-tokenpocket'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'
import WebRenderer from '@wharfkit/web-renderer'
import { ACCOUNT_NAME, CODE_WEB3_NAME, NODE_URL } from "@/utils/config";


let contract: any;
let web3NameContract: any;
export const url = NODE_URL

export const client = new APIClient({ url })
export const accountKit = new AccountKit(Chains.EOS, { client })
export const contractKit = new ContractKit({
    client,
})


export const sessionKit = new SessionKit(
    {
        appName: 'YOCHAT',
        chains: [
            {
                id: Chains.EOS.id,
                url: url,
            },
        ],
        ui: new WebRenderer({ minimal: true }),
        walletPlugins: [
            new WalletPluginAnchor(),
            new WalletPluginTokenPocket(),
            new WalletPluginScatter(),
            new WalletPluginWombat(),
        ],
    },
    {
        transactPlugins: [new TransactPluginResourceProvider()],
    }
)

export async function login() {
    const result = await sessionKit.login()
    return result.session;
}

export async function logout() {
    await sessionKit.logout()
}

export async function restore() {
    const restored = await sessionKit.restore()
    return restored;
}

export const getContract = async () => {
    if (contract) {
        return contract
    }
    contract = await contractKit.load(ACCOUNT_NAME);
    return contract;
}

export const getWeb3NameContract = async () => {
    if (web3NameContract) {
        return web3NameContract
    }
    web3NameContract = await contractKit.load(CODE_WEB3_NAME);
    return web3NameContract;
}