<template>
  <n-spin :show="isLoading">
    <n-tabs type="line" animated @update-value="handleChangeTab">
      <n-tab-pane
        v-for="item in dataInfo.options"
        :key="item.value"
        :name="item.label"
        :tab="item.label"
      >
        <div class="msg-list" id="msgList">
          <div v-for="item in msgList" class="msg-item" :key="item.id">
            <img class="msg-img" :src="item.avatar || DEFAULT_AVATAR" />
            <div class="msg-content" @click="handleDetail(item)">
              <div class="msg-title">{{ item.parameter }}</div>
              <div class="msg-info">
                <div
                  class="msg-info-author"
                  @click.stop="handleUserDetail(item.author)"
                >
                  <strong>{{ item.web3Name || item.author }}</strong>
                </div>
                <div>{{ timeFromNow(item.time) }}</div>
                <div v-if="item.sort.length > 0">
                  <n-tag
                    v-for="tag in item.sort.split(',')"
                    :key="tag"
                    type="info"
                    size="small"
                  >
                    {{ tag }}
                  </n-tag>
                </div>
              </div>
            </div>
            <div>
              <div class="msg-replynum">{{ item.replynum }}</div>
            </div>
          </div>

          <!-- <div class="msg-pagination" v-if="msgList.length > 0">
            <div style="flex: 1"></div>
            <n-pagination
              v-model:page="dataInfo.currentPage"
              :page-count="Math.ceil(dataInfo.total / dataInfo.pageSize)"
              @update-page="updatePage"
            />
          </div> -->
        </div>
      </n-tab-pane>
    </n-tabs>
  </n-spin>
</template>

<script lang="ts">
import { Ref, ref, defineComponent, onMounted, reactive } from "vue";
import { UInt64 } from "@wharfkit/antelope";
import { NSpin, NTag, useMessage, NTabs, NTabPane } from "naive-ui";
import { timeFromNow } from "@/utils/index";
import { DEFAULT_AVATAR, ACCOUNT_NAME } from "@/utils/config";
import { getContract, getWeb3NameContract } from "@/utils/wharf";
import { useRouter } from "vue-router";

interface IMessage {
  index: number;
  id: number;
  author: string;
  parameter: string;
  chat: string;
  time: number;
  textid: number;
  replynum: number;
  sort: string;
  web3Name: string;
  avatar: string;
}

export default defineComponent({
  name: "MsgListComponent",
  props: ["scope"],
  components: {
    NSpin,
    NTag,
    NTabs,
    NTabPane,
  },
  setup() {
    const message = useMessage();
    const router = useRouter();
    const msgList: Ref<IMessage[]> = ref([]);
    const isLoading: Ref<boolean> = ref(true);
    const allWeb3Name: any = ref({});
    const allAvatar: any = ref({});
    let dataInfo = reactive({
      currentPage: 1,
      total: 0,
      pageSize: 10,
      options: [{ label: "全部", value: "all" }],
    });

    onMounted(async () => {
      getSortList();
      getMsgList({ from: "all" });
    });

    const getSortList = async () => {
      try {
        const contract = await getContract();
        const res = await contract.table("sort").all({ scope: ACCOUNT_NAME });
        const newList = res.map((v: { id: UInt64; sorttext: string }) => {
          return {
            label: v.sorttext,
            value: v.id,
          };
        });
        dataInfo.options = [...dataInfo.options, ...newList];
      } catch (error: any) {
        message.error(error.message);
      }
    };

    const getMsgList = async ({ from }: { from: any }) => {
      try {
        isLoading.value = true;
        let params: any = {
          json: true,
          maxRows: 1000,
          reverse: true,
          key_type: "i64",
        };
        if (from != "all") {
          params.index_position = 3;
          params.from = UInt64.from(from);
          params.to = UInt64.from(from);
        } else {
          params.index_position = 1;
        }
        const contract = await getContract();
        const rows = await contract.table("chatidtable").all(params);
        if (rows.length > 0) {
          dataInfo.total = rows[0].id;
          msgList.value = rows;
        } else {
          dataInfo.total = 0;
          msgList.value = [];
        }
        isLoading.value = false;
        getAllWeb3Name();
        // getAllAvatar();
      } catch (error: any) {
        isLoading.value = false;
        msgList.value = [];
        message.error(error.message);
      }
    };

    const changeMsgPage = async () => {
      try {
        isLoading.value = true;
        const to =
          dataInfo.total - (dataInfo.currentPage - 1) * dataInfo.pageSize;

        let params: any = {
          json: true,
          maxRows: 10,
          reverse: true,
          key_type: "i64",
          to: UInt64.from(to),
        };
        const contract = await getContract();
        const rows = await contract.table("chatidtable").all(params);
        msgList.value = rows;
        getAllWeb3Name();
        getAllAvatar();
        isLoading.value = false;
      } catch (error: any) {
        isLoading.value = false;
        msgList.value = [];
        message.error(error.message);
      }
    };
    /**
     * 进入详情页
     * @param _scope 用户名
     */
    const handleUserDetail = (_scope: string) => {
      router.push({
        path: "/userdetail",
        query: {
          scope: _scope,
        },
      });
    };

    const handleDetail = (_message: IMessage) => {
      console.log("handleDetail", _message);
      router.push({
        path: "/chatdetail",
        query: {
          id: _message.id,
          scope: _message.author,
          textid: _message.textid,
        },
      });
    };

    /**
     * 切换页码
     */
    const updatePage = (page: number) => {
      dataInfo.currentPage = page;
      changeMsgPage();
    };

    const getAllWeb3Name = async () => {
      const allPromiseList: any = [];
      const allAvatarPromiseList: any = [];
      msgList.value.forEach((v) => {
        allWeb3Name.value[v.author] = v;
      });

      Object.keys(allWeb3Name.value).forEach((v: any) => {
        allPromiseList.push(getWeb3Name(v));
        allAvatarPromiseList.push(getUserAvatar(v));
      });
      Promise.all(allPromiseList).then((res) => {
        Object.keys(allWeb3Name.value).forEach((v: string, index: number) => {
          allWeb3Name.value[v].web3Name = res[index];
        });
        msgList.value.forEach((v: any) => {
          v.web3Name = allWeb3Name.value[v.author].web3Name;
        });
      });
      Promise.all(allAvatarPromiseList).then((res) => {
        Object.keys(allWeb3Name.value).forEach((v: string, index: number) => {
          allWeb3Name.value[v].avatar = res[index];
        });
        msgList.value.forEach((v: any) => {
          v.avatar = allWeb3Name.value[v.author].avatar;
        });
      });
    };

    const getAllAvatar = async () => {
      const allPromiseList: any = [];
      msgList.value.forEach((v: { author: string }) => {
        allAvatar.value[v.author] = v;
        // allPromiseList.push(getUserAvatar(v.author));
      });
      const allList = await Promise.all(allPromiseList);

      msgList.value.forEach((v: IMessage, index: number) => {
        v.avatar = allList[index];
      });
    };

    const getWeb3Name = async (_scope: string) => {
      const nameContract = await getWeb3NameContract();
      const res = await nameContract.table("holderid").all({ scope: _scope });
      if (res && res.length > 0) {
        return res[0].web3name;
      }
      return "";
    };

    const getUserAvatar = async (_scope: string) => {
      const contract = await getContract();
      const res = await contract.table("avatartable").get(_scope);
      if (res) {
        return res.avatar;
      }
      return DEFAULT_AVATAR;
    };

    const handleChangeTab = (e: string) => {
      const selIndex = dataInfo.options.findIndex((v) => v.label == e);
      if (selIndex != -1) {
        dataInfo.currentPage = 1;
        const selForm = dataInfo.options[selIndex].value;
        getMsgList({
          from: selForm,
        });
      }
    };

    return {
      dataInfo,
      DEFAULT_AVATAR,
      isLoading,
      msgList,
      updatePage,
      timeFromNow,
      handleDetail,
      handleChangeTab,
      handleUserDetail,
    };
  },
});
</script>

<style scoped lang="scss">
.msg {
  &-list {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    padding: 0 12px;
  }
  &-item {
    gap: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #e2e8f0;
    padding: 20px 0;
  }
  &-spin {
    text-align: center;
    padding: 10px 0;
  }
  &-info {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #64748b;
    gap: 25px;
    font-weight: 500;
    &-author {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: #1e293b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-chat {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  &-img {
    width: 48px;
    height: 48px;
    border-radius: 5px;
  }
  &-content {
    width: 80%;
    flex: 1 1 0;
  }

  &-replynum {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    user-select: none;
    color: #64748b;
    background-color: #e2e8f0;
    padding: 5px 10px;
    white-space: nowrap;
    border-radius: 5px;
  }
  &-pagination {
    margin: 10px 0;
    display: flex;
  }
}

@media (max-width: 750px) {
  .msg {
    &-item {
      gap: 0;
    }
    &-img {
      display: none;
    }
  }
}

::v-deep {
  .n-tabs .n-tabs-nav {
    padding-left: 12px;
  }
  .n-tabs-tab {
    color: #64748b;
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .n-tabs-tab--active {
    color: #1e293b !important;
  }
}
</style>
