<template>
  <n-message-provider>
    <router-view :key="key" />
  </n-message-provider>
</template>

<script lang="ts">
import { defineComponent, computed, onBeforeMount } from "vue";
import { NMessageProvider } from "naive-ui";
import { useRoute } from "vue-router";
import { contractKit } from "@/utils/wharf";
import { ACCOUNT_NAME } from "@/utils/config";
import { useUserInfoStore } from "@/stores/userInfo/index";

export default defineComponent({
  name: "App",
  components: {
    NMessageProvider,
  },
  setup() {
    const route = useRoute();
    const key = computed(() => route.path + Math.random());
    const userInfoStore = useUserInfoStore();

    onBeforeMount(() => {
      setContract();
    });

    const setContract = async () => {
      const contract = await contractKit.load(ACCOUNT_NAME);
      console.log("初始化---", contract);

      userInfoStore.setContract(contract);
    };

    return {
      key,
    };
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-color: #f2f3f5;
  min-height: 100vh;
  overflow: scroll;
}

body {
  margin: 0;
}

.page {
  display: flex;
  max-width: 1100px;
  margin: 60px auto 0 auto;
  gap: 25px;
}

.flex {
  display: flex;
}

.flex1 {
  display: flex;
  flex: 1;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}
@media (max-width: 750px) {
  .page {
    margin: 55px auto 0 auto;
    gap: 0;
  }
}
</style>
