import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isLeapYear from 'dayjs/plugin/isLeapYear' // 导入插件
import 'dayjs/locale/zh-cn' // 导入本地化语言
import { getContract, getWeb3NameContract } from './wharf';
import { DEFAULT_AVATAR } from './config'
import { verifyWord } from './censoredWords';

dayjs.extend(isLeapYear) // 使用插件
dayjs.locale('zh-cn') // 使用本地化语言

dayjs.extend(relativeTime);

const timeFromNow = (time: number) => {
    const date = dayjs.unix(time);
    const diff = dayjs().diff(date, 'day');

    if (diff > 3) {
        return date.format('YYYY-MM-DD HH:mm:ss');
    } else {
        return date.fromNow();
    }
}


/**
 * 渲染富文本
 * @param text 
 * @returns 
 */
const handleRenderHtml = (text: string) => {
    if (!text) {
        return ''
    }
    return text
        .replace(
            // eslint-disable-next-line no-useless-escape
            /\<img/gi,
            '<img style="max-width: 100%;height:auto;"'
        )
        .replace(
            // eslint-disable-next-line no-useless-escape
            /\<video/gi,
            '<video style="max-width: 100%;height:auto;"'
        );
}


const getWeb3Name = async (_scope: string) => {
    const nameContract = await getWeb3NameContract();
    const res = await nameContract.table("holderid").all({ scope: _scope });
    if (res && res.length > 0) {
        return res[0].web3name;
    }
    return "";
};

const getUserAvatar = async (_scope: string) => {
    const contract = await getContract();
    const res = await contract.table("avatartable").get(_scope);
    if (res) {
        return res.avatar;
    }
    return DEFAULT_AVATAR;
};

const getUserPit = async (_scope: string) => {
    const contract = await getContract();
    const res = await contract.table("pitable").get(_scope);
    if (res) {
        return res.info;
    }
    return '他很懒，什么都没填';
}

export {
    getUserPit,
    getUserAvatar,
    getWeb3Name,
    verifyWord,
    timeFromNow,
    handleRenderHtml
}