<template>
  <div class="header">
    <div class="content">
      <div class="nav">
        <div class="nav-title flex" @click="handleHome">
          <svg width="200" height="33.6" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient
                id="A"
                x1="0.77489"
                y1="0.31099"
                x2="0.22641"
                y2="0.69611"
              >
                <stop offset="0" stop-color="#fc5b3f" />
                <stop offset="0.284" stop-color="#f8573d" />
                <stop offset="0.579" stop-color="#eb4b38" />
                <stop offset="0.877" stop-color="#d73730" />
                <stop offset="1" stop-color="#cc2c2c" />
              </linearGradient>
              <linearGradient
                id="B"
                x1="0.15902"
                y1="0.63069"
                x2="0.80598"
                y2="0.36638"
              >
                <stop offset="0" stop-color="#fc5b3f" />
                <stop offset="0.284" stop-color="#f8573d" />
                <stop offset="0.579" stop-color="#eb4b38" />
                <stop offset="0.877" stop-color="#d73730" />
                <stop offset="1" stop-color="#cc2c2c" />
              </linearGradient>
            </defs>
            <g>
              <title>Layer 1</title>
              <path
                d="m31.2,5.6l-1,1.1l2.5,6.4l3.9,3.5l2.8,-3.4l-1.7,-5.2l-4.4,-4.5c-0.8,0.7 -1.5,1.3 -2.1,2.1z"
                fill="url(#A)"
                id="svg_1"
              />
              <path
                d="m39.5,13.1c1.1,-1.2 2.5,-1.9 4,-1.9c2.2,0 5.7,1.5 5.7,5.6s-3.5,5.6 -5.7,5.6c-1.5,0 -2.9,-0.6 -4,-1.9l-2.8,-4l-6.5,-9.9l-1.3,11.4l1.3,8.9l1,1.1c2.4,2.7 5.5,4.5 8.9,5.2c3.2,0.7 6.5,0.4 9.6,-0.8s5.7,-3.2 7.6,-5.8c2,-2.8 3.1,-6.2 3.1,-9.8s-1.1,-7 -3.1,-9.8c-1.9,-2.6 -4.5,-4.6 -7.6,-5.8s-6.4,-1.5 -9.6,-0.8c-2.5,0.5 -4.8,1.6 -6.8,3.2l6.2,9.5z"
                fill="#fc5b3f"
                id="svg_2"
              />
              <path
                d="m29.2,28.1l1,-1.1l-2.5,-6.4l-3.9,-3.5l-2.8,3.4l1.7,5.1l4.4,4.5c0.7,-0.6 1.5,-1.3 2.1,-2z"
                fill="url(#B)"
                id="svg_3"
              />
              <path
                d="m20.9,20.5c-1.1,1.2 -2.5,1.9 -4,1.9c-2.2,0 -5.7,-1.5 -5.7,-5.6s3.5,-5.6 5.7,-5.6c1.5,0 2.9,0.6 4,1.9l2.8,4l6.5,9.9l0,-20.4l-1,-1.1c-2.4,-2.7 -5.5,-4.5 -8.9,-5.2c-3.2,-0.7 -6.5,-0.4 -9.6,0.8s-5.7,3.2 -7.6,5.9c-2,2.8 -3.1,6.2 -3.1,9.8s1.1,7 3.1,9.8c1.9,2.6 4.5,4.6 7.6,5.8s6.4,1.5 9.6,0.8c2.5,-0.5 4.8,-1.6 6.8,-3.2l-6.2,-9.5z"
                fill="#fc5b3f"
                id="svg_4"
              />
              <text
                fill="#000000"
                stroke="null"
                stroke-width="0"
                x="58"
                y="27.29996"
                id="svg_7"
                font-size="29"
                font-family="'Asap'"
                text-anchor="start"
                xml:space="preserve"
                font-weight="bold"
              >
                YOCHAT
              </text>
            </g>
          </svg>
        </div>
      </div>
      <div class="nav">
        <div v-if="!userInfo" class="nav-tools-login" @click="handleLogin">
          连接
        </div>
        <n-dropdown
          v-else
          trigger="hover"
          size="medium"
          :options="options"
          @select="handleMenuSelect"
        >
          <div class="nav-tools-login">
            {{ userInfo.actor.toString() }}
          </div>
        </n-dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { login, restore, logout } from "@/utils/wharf";
import { useUserInfoStore } from "@/stores/userInfo/index";
import { useRouter } from "vue-router";
import { NDropdown } from "naive-ui";

export default defineComponent({
  name: "HeaderComponent",
  components: { NDropdown },
  setup() {
    const options = [
      {
        label: "我要发帖",
        key: "sendMsg",
      },
      {
        label: "我的主页",
        key: "myhome",
      },
      {
        label: "断开",
        key: "disconnect",
      },
    ];
    const userInfoStore = useUserInfoStore();
    const router = useRouter();
    let loginInfo: any;
    onMounted(async () => {
      loginInfo = await restore();
      if (loginInfo) {
        userInfoStore.setSession(loginInfo);
        userInfoStore.setWeb3Name(loginInfo.actor.toString());
      }
    });

    // 通过计算拿到里面的数据
    const userInfo: any = computed(() => userInfoStore.session);

    const handleLogin = async () => {
      if (userInfo.value) {
        return;
      }
      const session = await login();
      userInfoStore.setSession(session);
      location.reload();
    };

    const handleLogOut = async () => {
      await logout();
      userInfoStore.setSession(null);
    };

    const handleHome = () => {
      router.push("/");
    };

    const handleMenuSelect = (key: string | number) => {
      switch (key) {
        case "sendMsg":
          router.push({
            path: "/sendmsg",
          });
          break;
        case "myhome":
          router.push({
            path: "/userdetail",
            query: {
              scope: loginInfo.actor.toString(),
            },
          });
          break;
        case "disconnect":
          handleLogOut();
          break;
        default:
          break;
      }
    };

    return {
      userInfo,
      handleLogin,
      handleLogOut,
      handleHome,
      handleMenuSelect,
      options,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 50px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  background-size: 44px 44px;
  padding: 0 20px;
  width: 100%;
  z-index: 999;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
}

.content {
  max-width: 1060px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
}
.nav {
  display: flex;
  align-items: center;
  gap: 8px 14px;

  &-tools {
    &-item {
      cursor: pointer;
      line-height: 55px;
      color: #64748b;
    }
    &-login {
      cursor: pointer;
      display: inline-flex;
      position: relative;
      align-items: center;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      padding: 0px 16px;
      background-color: #2080f0;
      color: #fff;
      height: 30px;
      box-shadow: 0 3px 5px 0 rgba(32, 128, 240, 0.4);
    }
  }
  &-title {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
  }
}

@media (max-width: 750px) {
  .header {
    padding: 0 10px;
  }
  .nav-title {
    text {
      display: none;
    }
  }
}
</style>
