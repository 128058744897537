<template>
  <div>
    <Header />
    <div class="page">
      <div></div>
      <div class="content">
        <MsgList />
      </div>
      <div class="right">
        <div class="info" v-if="userInfo">
          <div class="info-actor">
            <div>
              <img class="info-img" :src="userAvatar" />
            </div>
            <div class="info-name">{{ web3Name }}</div>
          </div>
          <div class="info-desc">
            <div class="info-desc-item">
              <div class="info-num">{{ dataInfo.chatNum }}</div>
              <div>帖子</div>
            </div>
            <div class="info-desc-item">
              <div class="info-num">{{ dataInfo.followingNum }}</div>
              <div>关注</div>
            </div>
            <div class="info-desc-item">
              <div class="info-num">{{ dataInfo.followedNum }}</div>
              <div>粉丝</div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="info-send" @click="handleSendMsg">我要发帖</div>
        </div>
        <Topics />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, reactive } from "vue";
import { contractKit } from "@/utils/wharf";
import { ACCOUNT_NAME, DEFAULT_AVATAR, CODE_WEB3_NAME } from "@/utils/config";
import { useRouter } from "vue-router";
import Header from "@/components/header/index.vue";
import Topics from "@/components/topics/index.vue";
import { useUserInfoStore } from "@/stores/userInfo/index";
import MsgList from "./components/MsgList/index.vue";
import { useMessage } from "naive-ui";

export default defineComponent({
  name: "HomeView",
  components: {
    Topics,
    MsgList,
    Header,
  },
  setup() {
    const message = useMessage();
    const router = useRouter();
    const userInfoStore = useUserInfoStore();
    let contract: any;
    let nameContract: any;
    onMounted(async () => {
      contract = await contractKit.load(ACCOUNT_NAME);
      nameContract = await contractKit.load(CODE_WEB3_NAME);
      getAuthorInfo();
      getWeb3Name();
    });
    // 通过计算拿到里面的数据
    const userInfo: any = computed(() => userInfoStore.session);
    const userAvatar: any = computed(() => userInfoStore.avatar);
    const web3Name: any = computed(() => userInfoStore.web3Name);
    const dataInfo = reactive({
      followingNum: 0,
      followedNum: 0,
      chatNum: 0,
    });
    /**
     * 成功发送消息
     */
    const handleSendMsg = async () => {
      if (!userInfo.value) {
        message.warning("请登录");
        return;
      }
      router.push({
        path: "/sendmsg",
      });
    };

    /**
     * 获取作者数据
     */
    const getAuthorInfo = async () => {
      if (!userInfo.value) {
        return;
      }
      contract
        .table("following")
        .all({
          scope: userInfo.value.actor,
          json: true,
          maxRows: 1000,
        })
        .then((res: Array<any>) => {
          dataInfo.followingNum = res.length;
        });

      contract
        .table("followed")
        .all({
          scope: userInfo.value.actor,
          json: true,
          maxRows: 1000,
        })
        .then((res: Array<any>) => {
          dataInfo.followedNum = res.length;
        });

      contract
        .table("chattable")
        .all({
          scope: userInfo.value.actor,
          maxRows: 1000,
          reverse: true,
        })
        .then((res: Array<any>) => {
          dataInfo.chatNum = res.length;
        });

      contract
        .table("avatartable")
        .get(userInfo.value.actor.toString())
        .then((res: { avatar: string }) => {
          if (res) {
            userInfoStore.setAvatar(res.avatar);
          }
        });
    };

    /**
     * 获取web3Name
     */
    const getWeb3Name = () => {
      if(!userInfo.value) {
        return;
      }
      nameContract
        .table("holderid")
        .all({ scope: userInfo.value.actor })
        .then((res: any) => {
          if (res && res.length > 0) {
            userInfoStore.setWeb3Name(res[0].web3name);
          }
        });
    };

    return {
      userAvatar,
      DEFAULT_AVATAR,
      dataInfo,
      userInfo,
      web3Name,
      handleSendMsg,
    };
  },
});
</script>

<style scoped lang="scss">
.content {
  // height: calc(100vh - 55px - 20px);
  min-height: 600px;
  width: 600px;
  max-width: 85vw;
  background-color: #fff;
  flex: 1 1 0%;
  border-radius: 10px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 4%);
  color: #1e293b;
}

.msg {
  &-item {
    padding: 10px 16px;
    border-bottom: 1px solid;
  }
  &-spin {
    text-align: center;
    padding: 10px 0;
  }
}

.right {
  width: 270px;
}

.info {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 4%);
  border-radius: 10px;
  margin-bottom: 20px;
  &-actor {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &-name {
    font-weight: bold;
    color: #1e293b;
  }
  &-img {
    width: 48px;
    height: 48px;
    border-radius: 5px;
  }

  &-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    &-item {
      cursor: pointer;
      flex: 1;
      text-align: center;
      color: #64748b;
      font-weight: bold;
    }
  }
  &-resource {
    &-item {
      display: flex;
      color: #64748b;
      font-weight: bold;
      justify-content: space-between;
    }
  }
  &-send {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    color: #0060f0;
    font-weight: 500;
  }
  &-num {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #252933;
  }
}

.card {
  color: #1e293b;
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 4%);
  border-radius: 10px;
  margin-bottom: 20px;
}

@media (max-width: 750px) {
  .right {
    display: none;
  }

  .content {
    border-radius: 5px;
    max-width: 98vw;
    margin: 0 auto;
    height: calc(100vh - 55px - 5px);
  }
}
</style>
