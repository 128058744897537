<template>
  <div class="topics">
    <div class="topics-title">热议主题</div>
    <div class="topics-list">
      <n-spin size="small" :show="info.isLoading">
        <a
          v-for="item in info.list"
          :key="item.id"
          class="topics-item"
          target="_blank"
          @click="handleDetail(item)"
        >
          {{ item.parameter }}
        </a>
      </n-spin>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { contractKit } from "@/utils/wharf";
import { ACCOUNT_NAME } from "@/utils/config";
import { NSpin } from "naive-ui";
import { useRouter } from "vue-router";

interface IMessage {
  index: number;
  id: number;
  author: string;
  parameter: string;
  chat: string;
  time: number;
  textid: number;
  replynum: number;
}

export default defineComponent({
  name: "TopicsCard",
  components: {
    NSpin,
  },
  setup() {
    const router = useRouter();

    let info: any = reactive({
      isLoading: false,
      list: [],
    });

    let contract: any;

    onMounted(async () => {
      contract = await contractKit.load(ACCOUNT_NAME);
      getMsgList();
    });

    const getMsgList = async () => {
      try {
        info.isLoading = true;
        let params: any = {
          json: true,
          maxRows: 1000,
          reverse: true,
        };

        const rows = await contract.table("chatidtable").all(params);
        const newlist = rows
          .filter((v: any) => v.parameter)
          .sort((a: IMessage, b: IMessage) => b.replynum - a.replynum)
          .slice(0, 10);

        info.list = newlist;
        info.isLoading = false;
      } catch (error) {
        info.isLoading = false;
      }
    };

    const handleDetail = (_message: IMessage) => {
      router.push({
        path: "/chatdetail",
        query: {
          id: _message.id,
          scope: _message.author,
          textid: _message.textid,
        },
      });
    };

    return {
      info,
      handleDetail,
    };
  },
});
</script>

<style scoped lang="scss">
.topics {
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 4%);
  border-radius: 10px;
  &-title {
    padding: 10px;
    border-bottom: 1px solid #e2e8f0;
    font-weight: bold;
  }
  &-list {
    padding: 10px;
  }
  &-item {
    cursor: pointer;
    display: block;
    color: #09408e;
    word-break: break-word;
    font-size: 14px;
    margin-bottom: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
