import { defineStore, acceptHMRUpdate } from 'pinia';
import { DEFAULT_AVATAR } from '@/utils/config'

export const useUserInfoStore = defineStore({
    id: 'userInfo',
    state: () => ({
        contract: null,
        session: null,
        topics: [],
        avatar: DEFAULT_AVATAR,
        web3Name: '--',
    }),
    actions: {
        setContract(data: any) {
            this.contract = data;
        },
        setSession(data: any) {
            this.session = data;
        },
        setTopics(data: any) {
            this.topics = data;
        },
        setAvatar(data: string) {
            this.avatar = data;
        },
        setWeb3Name(_data: string) {
            this.web3Name = _data;
        }
    }
});


// 确保传递正确的 store 声明，本例中为 `useAuth`
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.webpackHot))
}